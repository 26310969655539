/*
 * Resources: 
 * - https://scrimba.com/scrim/cpqd9rta
 * - https://tinloof.com/blog/how-to-build-an-auto-play-slideshow-with-react/
 */

import React from 'react';
import './slides.css';
export const Slider = ({ slides }) => {

  // Current is the index of image being displayed.
  const [current, setCurr] = React.useState(0);
  const timeoutRef = React.useRef(null);
  const timer = 5000;
  const { length } = slides;

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => 
      setCurr(current === length - 1 ? 0 : current + 1), timer
    );
    return () => {
      resetTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);
  
  if (!Array.isArray(slides) || length <= 0) {
    return null;
  }
  
  return (
    <div className="slideshow">
      <div className="slideshowSlider">
        {slides.map((s, i) => ( 
          <div className={i === current ? "slide active" : "slide"} key={s.title} aria-hidden={i !== current}>
            <div className="image_title">
              <h1>{s.title}</h1>
            </div>
            {i === current && (<img className="image" src={s.image} alt={s.title} />)}
          </div>          
        ))}
      </div>
      <div className="slideshowDots">
        {slides.map((_, idx) => (
          <div key={idx} className={`slideshowDot${current === idx ? " active" : ""}`} onClick={() => { setCurr(idx);}}>
          </div>
        ))}
      </div>
    </div>
  );  
}
