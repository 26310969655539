import React from 'react';
import { NavLink } from 'react-router-dom';

import './SideDrawer.css';

export const sideDrawer = props => {
  let drawerClasses = 'side-drawer';
  if (props.show) {
    drawerClasses = 'side-drawer open';
  }

  return (
    <nav className={drawerClasses} >
      <ul>
        <li><NavLink to="/" activeClassName="active" exact onClick={props.click}>Home</NavLink></li>
        <li><NavLink to="/services" activeClassName="active" onClick={props.click}>Services</NavLink></li>
        <li><NavLink to="/about" activeClassName="active" onClick={props.click}>About</NavLink></li>
        <li><NavLink to="/contact" activeClassName="active" onClick={props.click}>Contact</NavLink></li>
      </ul>
    </nav>
  )
}
