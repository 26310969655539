import React from 'react';
import './DrawerToggleButton.css';

export const drawerToggleButton = props => {
  let name = 'toggle-button';
  if (props.show) {
    name = 'toggle-button open';
  }
  
  return (
    <div className={name} onClick={props.click}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}
