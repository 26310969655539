import React from 'react';
import './Footer.css';

export const footer = props => {
  return (
    <footer>
      <p className="footer_text">
        Copyright © 2023 NetZero Builder<br/>
        California License # 1066936
      </p>
    </footer>
  )
}
