import React from 'react'

export const About = () => {
    return (
        <div>
            <h2 className="routeHeader">About</h2>
            <hr className="routeHeaderLineBreak" />
            <p className="routeSubHeader">
                Providing a variety of general contracting services in the northern California area.<br/><br/>
                After fifteen years of experience in the industry, NetZero Builder was created in 2020.<br/><br/>
                Bilingual in English and Spanish.<br/><br/>
            </p>
        </div>
    )
}
