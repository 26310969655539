/*
 * TODO:
 * - Build contact form:
 *  + https://www.pluralsight.com/guides/hiding-secret-keys-in-create-react-app
 *  + https://ciunkos.com/creating-contact-forms-with-nodemailer-and-react
 *  + https://blog.mailtrap.io/react-send-email/
 * 
 */

import React from 'react'
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import './Contact.css';

export const Contact = () => {
    return (
        <div>
            <h2 className="routeHeader">Contact</h2>
            <hr className="routeHeaderLineBreak" />
            <p className="routeSubHeader">To receive a bid for work or if you have any questions, please reach out via phone, text message or email.</p>
            <div id="contactPanel">
                <div id="contactLeftColumn">
                    <p className="boldgrid-google-maps">
                        <iframe id="map" title="google_map" loading="lazy" src="https://maps.google.com/maps?q=Hayward%2C%20CA&amp;t=m&amp;z=16&amp;output=embed" width="400" height="300" frameBorder="0" data-mce-fragment="1"></iframe>
                    </p> 
                </div>   
                <div id="contactRightColumn">
                    <p className="contactLocation" data-font-weight="600">Hayward, CA</p>
                    <p className="contactText">
                        <a href="tel:5109992963">
                            <FaPhone className="contactIcon"/>
                            <span className="contactLeftIndent">(510) 999-2963</span>
                        </a>
                    </p>
                    <p className="contactText" data-font-weight="400">
                        <a href="mailto:netzerobuilders@gmail.com">
                            <FaEnvelope className="contactIcon"/>
                            <span className="contactLeftIndent">NetZeroBuilders@gmail.com</span>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
}
