import React from 'react';
import {Link, NavLink }from 'react-router-dom';
import { drawerToggleButton as DrawerToggleButton } from '../sideDrawer/DrawerToggleButton';
import './Toolbar.css';
import Logo from '../../assets/trans_LogoBuilder_NoNums_Scaled.png';

export const toolbar = (props) => {

  return (
    <header className="toolbar">
      <nav className="toolbar__navigation">
        <Link to="/"><img className="toolbar__company-logo" src={Logo} alt="NetZero Builder logo" /></Link>
        <div className="spacer" />
        <div className="toolbar__toggle-button">
          <DrawerToggleButton click={props.drawerClickHandler} show={props.show}/>
        </div>
        <div className="desktop_nav">
          <NavLink to="/" exact className="nav_link">Home</NavLink>
          <NavLink to="/services" className="nav_link">Services</NavLink>
          <NavLink to="/about" className="nav_link">About</NavLink>
          <NavLink to="/contact" className="nav_link">Contact</NavLink>
        </div>
      </nav>
    </header>
  )
}

/*
  return (
    <header className="toolbar">
      <nav className="toolbar__navigation">
        <Link to="/"><img className="toolbar__company-logo" src={Logo} alt="NetZero Builder logo" /></Link>
        <div className="toolbar__company-name"><Link to="/">NetZero Builder</Link></div> -->
        <div className="spacer" />
        <div className="toolbar__toggle-button">
          <DrawerToggleButton click={props.drawerClickHandler} show={props.show}/>
        </div>
      </nav>
    </header>
  )   
*/