import React from 'react';
import { Slider } from '../components/slides/slides';
import defaultSlides from '../components/slides/defaultSlides';
import './Services.css';

export const Services = () => {
    return (
        <div>
            <h2 className="routeHeader">Services Provided</h2>
            <hr className="routeHeaderLineBreak" />
            <Slider slides={defaultSlides} />
        </div>
    )
}
