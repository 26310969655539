import designBuild from '../../assets/blueprints.jpg';
import raisedAccessFloor from '../../assets/raisedAccessFloor.jpg';
import tenantImprovements from '../../assets/steelStuds.jpg';
import newConstruction from '../../assets/homeFraming.jpg';
import kitchenRemodel from '../../assets/whiteKitchen_front.jpg';
import bathroomRemodel from '../../assets/bathroomRemodel.jpg';

let slides = [
  {
    title: 'Design Build',
    subtitle: 'Image of blueprints.',
    image: designBuild
  },
  {
    title: 'Raised Access Floor',
    subtitle: 'Image of raised access floor.',
    image: raisedAccessFloor
  },
  {
    title: 'Tenant Improvements',
    subtitle: 'Image of steel studs.',
    image: tenantImprovements
  },
  {
    title: 'New Construction / ADU',
    subtitle: 'Image of wooden studs.',
    image: newConstruction
  },
  {
    title: 'Kitchen Remodel',
    subtitle: 'Image of white themed kitchen.',
    image: kitchenRemodel
  },
  {
    title: 'Bathroom Remodel',
    subtitle: 'Image of white themed batchroom.',
    image: bathroomRemodel
  }
];

export default slides;