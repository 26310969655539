/* Resources:
 * - https://codepen.io/designcouch/pen/Atyop
 * - https://www.academind.com/learn/react/snippets/navbar-side-drawer/
 * - https://github.com/maximakymenko/react-burger-menu-article-app/blob/master/src/components/Burger/Burger.js
 * - https://stackoverflow.com/questions/54648705/how-to-change-button-icon-when-button-is-clicked-in-react-js
 * - https://stackoverflow.com/questions/44446671/my-position-sticky-element-isnt-sticky-when-using-flexbox
 * 
 */

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';

// Components
import { toolbar as Toolbar } from './components/toolbar/Toolbar';
import { sideDrawer as SideDrawer } from './components/sideDrawer/SideDrawer';
import { Layout } from './components/Layout';
import { footer as Footer } from './components/footer/Footer';
import ScrollToTop from './components/scrollToTop/scrollToTop';

// Routes
import { Home } from './routes/Home';
import { Services } from './routes/Services';
import { About } from './routes/About';
import { Contact } from './routes/Contact';
import { Error } from './routes/Error';

class App extends Component {
  state = {
    sideDrawerOpen: false,
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <ScrollToTop>
            <Toolbar drawerClickHandler={this.drawerToggleClickHandler} show={this.state.sideDrawerOpen}/>
            <SideDrawer click={this.drawerToggleClickHandler} show={this.state.sideDrawerOpen}/>
              <main>
                <Layout>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/services" component={Services} />
                    <Route path="/about" component={About} />
                    <Route path="/contact" component={Contact} />
                    <Route component={Error} />
                  </Switch>
                </Layout>
              </main>
            <Footer/>
          </ScrollToTop>
        </Router>
      </React.Fragment>
    )
  }
}

export default App;

// <main style={{ float: "right", width: "70%", padding: "10px", marginTop: '64px' }}>