import React from 'react'

export const Error = () => {
    return (
        <div>
            <h2>Error</h2>
            <p>Page not found...</p>
        </div>
    )
}
