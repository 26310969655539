import React from 'react';

import './Home.css';
import headerImage from '../assets/homeHeader.jpg';

export const Home = (props) => {
    return (
        <div>
            <div id="homeHeaderImage" style={{ backgroundImage: `linear-gradient(to left, rgba(19, 19, 19, 0.44), rgba(19, 19, 19, 0.44)),url(${headerImage})`}}>
                <div id="homeHeaderContainer">
                    <div id="leftHomeHeaderColumn">
                        <p>&nbsp;</p>
                    </div>
                    <div id="middleHomeHeaderColumn">
                        <h1 id="homeHeaderCompanyName">
                            NetZero Builder
                        </h1>
                        <p id="homeHeaderCompanyType">
                            General Contractor / C61
                        </p>
                        <div id="homeHeaderLineSeperator"></div>
                        <p id="homeHeaderCompanyMotto">
                            Working to reduce our carbon footprint to ensure a brighter future.
                        </p>
                    </div>
                    <div id="rightHomeHeaderColumn">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
